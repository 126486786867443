import { useMemo } from 'react'
import {
  GetUserRequestParams,
  GetUserResponse,
  PostUserCreateRequestBody,
  PostUserCreateResponse,
} from '@brilltek42/template-types'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { UserLoginFormDataType } from '@/components/organisms'

import { API } from '@/utils/API'

import { ROLE_ID_MAP, ROLE_ROUTE_PERMISSIONS } from '@/constants/permissions'

import { useAPI } from './useAPI'
import { useToken } from './useToken'

export const useAuth = () => {
  const { request } = useAPI()
  const tokenStore = useToken()
  /**
   * @description API `POST` request _(with credentials)_ to obtain the **JWT token**.
   */
  const getToken: MutationFunction<
    any,
    Omit<UserLoginFormDataType, 'system'>
  > = async (credentials) => {
    const res = await request('post', API.routes.user.login, {
      data: credentials,
    })
    return res.data
  }

  const loginMutate = useMutation<
    any,
    AxiosError,
    Omit<UserLoginFormDataType, 'system'>
  >({
    mutationFn: getToken,
  })
  const login = async (credentials: Omit<UserLoginFormDataType, 'system'>) => {
    const tokenRes = await loginMutate.mutateAsync(credentials)

    if (loginMutate.isError) {
      if (axios.isAxiosError(loginMutate.error)) {
        throw loginMutate.error.response?.data.errors
      } else {
        throw loginMutate.error
      }
    } else {
      // tokenStore.updateToken(tokenRes.token)
      tokenStore.updateUserId(tokenRes.id)

      return tokenRes.token
    }
  }

  const logout = () => {
    tokenStore.clear()
  }

  const postUserCreate: MutationFunction<
    PostUserCreateResponse,
    PostUserCreateRequestBody
  > = async (newUserData) => {
    const { data } = await request<PostUserCreateResponse>(
      'post',
      API.routes.user.register,
      {
        data: newUserData,
      },
    )
    return data
  }

  const register = useMutation({ mutationFn: postUserCreate })

  const isAuthenticated = useMemo(
    () => Boolean(tokenStore.token),
    [tokenStore.token],
  )

  const getUserData: QueryFunction<GetUserResponse> = async () => {
    const { data } = await request<
      GetUserResponse,
      never,
      GetUserRequestParams
    >('get', API.routes.user.data(tokenStore.userId), {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      params: { id: tokenStore.userId } as GetUserRequestParams,
    })

    return data
  }

  const userData = useQuery({
    queryKey: ['user', tokenStore.userId],
    queryFn: getUserData,
    enabled: isAuthenticated,
  })

  const checkRolePermission = (to: string) => {
    if (!userData.data) return false
    const userRole = userData.data.roleId
    if (userRole === null) return false
    const roleName = ROLE_ID_MAP[userRole]
    if (ROLE_ROUTE_PERMISSIONS[roleName].includes('*')) return true
    return ROLE_ROUTE_PERMISSIONS[roleName].includes(to)
  }

  return {
    login,
    logout,
    isAuthenticated,
    userData,
    register,
    checkRolePermission,
  }
}
