import { PATHS } from './path'

export const ROLE_ID_MAP: Record<number, RoleType> = {
  1: 'Manager',
  2: 'operator',
  3: 'Team Leader',
}

export const ROLE_ROUTE_PERMISSIONS: Record<RoleType, string[]> = {
  'Manager': ['*'],
  'operator': ['*'],
  'Team Leader': [PATHS.login, PATHS.workOrder, PATHS.inAndExit],
}

export const getRoutePermissions = (RoleType: RoleType) => {
  const allowedRoutes = ROLE_ROUTE_PERMISSIONS[RoleType] || []
  return allowedRoutes.includes('*')
    ? Object.values(PATHS)
    : Object.values(PATHS).filter((path) => !allowedRoutes.includes(path))
}
